<script>
import { addClass } from '@/helpers/text-editor-helper.js';

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    textContent() {
      return addClass(this.block.fields.Body, 'centered-text');
    },
  },
};
</script>

<template>
  <div class="centered-text__container">
    <div
      class="centered-text__content text-editor-content"
      v-html="textContent"></div>
  </div>
</template>

<style>
  .centered-text__container {
    padding: 20px 10px;
    max-width: var(--site-max-width);
    margin: auto;
  }

  .centered-text__content {
    text-align: center;
    max-width: 650px;
    margin: auto;
  }

  h1.centered-text__text-element,
  h2.centered-text__text-element {
    line-height: 32px;
    margin-bottom: 15px;
  }

  p.centered-text__text-element {
    margin: 15px 0;
    line-height: 24px;
  }

  .centered-text__text-element:first-child {
    margin-top: 0;
  }

  .centered-text__text-element:last-child {
    margin-bottom: 0;
  }

  .centered-text__text-element > .primary-button,
  .centered-text__text-element > .secondary-button {
    width: 100%;
  }

  .centered-text__text-element:last-child > .primary-button,
  .centered-text__text-element:last-child > .secondary-button {
    margin-bottom: 0;
  }

  @media (--tabletAndDesktop) {
    .centered-text__text-element > .primary-button,
    .centered-text__text-element > .secondary-button {
      margin: 25px 0;
      width: auto;
    }
  }
</style>
